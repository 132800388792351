<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Kategori
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <multiselect 
                  id="category-name"
                  v-model="activityCategoryChoose"
                  v-validate="'required'"
                  label="name"
                  name="kategori"
                  track-by="name"
                  placeholder="Pilih..."
                  open-direction="bottom"
                  :options="dataActivityCategorys"
                  :searchable="true"
                  :loading="isLoading"
                  :close-on-select="true"
                  :max-height="500"
                  :show-no-results="true"
                  @select="onSelectCategory"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('kategori')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('kategori') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Sub-Kategori
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.name"
                v-validate="'required'"
                class="uk-input"
                type="text"
                name="nama"
                placeholder="Masukan nama sub-kategori..."
                :class="{'uk-form-danger': errors.has('nama')}"
              >
              <div
                v-show="errors.has('nama')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('nama') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-text-right">
          <button
            class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToBack"
          >
            <img
              v-lazy="`${images}/icon/close.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Batal
          </button>
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            @click="showSaveConfirmModal"
          >
            <img
              v-lazy="`${images}/icon/save.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Simpan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/id'
import { PREFIX_IMAGE } from '@/utils/constant'
import { STATUS } from '@/utils/constant'

export default {
  props: {
    dataActivity: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      isShowFarm: false,
      isLoading: false,
      images: PREFIX_IMAGE,
      status: STATUS,
      datepick: new Date(),
      statusSwitch: true,
      formData: {},
      activityCategoryChoose: {},
      dataActivityCategorys: []
    }
  },
  computed: {
    ...mapGetters({
      activityCategorys: 'activityCategory/activityCategorys'
    })
  },
  watch: {
    async dataActivity() {
      if (this.dataActivity) {
        this.formData = {
          id: this.dataActivity.id,
          name: this.dataActivity.name,
          activity_category_id: this.dataActivity.activity_category_id
        }
        this.activityCategoryChoose = { id: this.dataActivity.activity_category_id, name: this.dataActivity.activity_category }
  
        await this.getActivityCategory({is_all: true})
        this.dataActivityCategorys = this.activityCategorys ? this.activityCategorys : []
      }
    }
  },
  methods: {
    onChangeRole(event) {
      const valRole = event.target.options[event.target.options.selectedIndex].text
      if (valRole === 'Super Admin')
        this.isShowFarm = false
      else
        this.isShowFarm = true
    },
    ...mapActions({
      getActivityCategory: 'activityCategory/getActivityCategory'
    }),
    ...mapMutations({
      setModalEdit: 'subActivityCategory/SET_MODAL_EDIT'
    }),
    async handleFindRole () {
      this.setMetaRole({
        ...this.metaRole,
        ...this.metaFilterRole
      })
    },
    onSelectCategory(val) {
      this.formData.activity_category_id = val.id
    },
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    showSaveConfirmModal() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.setModalEdit(this.formData)
          window.UIkit.modal('#modal-save-confirm').show()
        }
      })
    }
  }
}
</script>
